exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-export-bibtex-jsx": () => import("./../../../src/pages/export-bibtex.jsx" /* webpackChunkName: "component---src-pages-export-bibtex-jsx" */),
  "component---src-templates-blog-jsx-content-file-path-src-content-blog-mdx": () => import("./../../../src/templates/blog.jsx?__contentFilePath=/opt/build/repo/src/content/blog.mdx" /* webpackChunkName: "component---src-templates-blog-jsx-content-file-path-src-content-blog-mdx" */),
  "component---src-templates-home-jsx-content-file-path-src-content-index-mdx": () => import("./../../../src/templates/home.jsx?__contentFilePath=/opt/build/repo/src/content/index.mdx" /* webpackChunkName: "component---src-templates-home-jsx-content-file-path-src-content-index-mdx" */),
  "component---src-templates-news-jsx-content-file-path-src-content-news-mdx": () => import("./../../../src/templates/news.jsx?__contentFilePath=/opt/build/repo/src/content/news.mdx" /* webpackChunkName: "component---src-templates-news-jsx-content-file-path-src-content-news-mdx" */),
  "component---src-templates-overview-jsx-content-file-path-src-content-jobs-index-mdx": () => import("./../../../src/templates/overview.jsx?__contentFilePath=/opt/build/repo/src/content/jobs/index.mdx" /* webpackChunkName: "component---src-templates-overview-jsx-content-file-path-src-content-jobs-index-mdx" */),
  "component---src-templates-overview-jsx-content-file-path-src-content-site-index-mdx": () => import("./../../../src/templates/overview.jsx?__contentFilePath=/opt/build/repo/src/content/site/index.mdx" /* webpackChunkName: "component---src-templates-overview-jsx-content-file-path-src-content-site-index-mdx" */),
  "component---src-templates-overview-jsx-content-file-path-src-content-students-projects-index-mdx": () => import("./../../../src/templates/overview.jsx?__contentFilePath=/opt/build/repo/src/content/students/projects/index.mdx" /* webpackChunkName: "component---src-templates-overview-jsx-content-file-path-src-content-students-projects-index-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-contact-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/contact.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-contact-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-faq-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/faq.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-faq-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-jobs-2024-nwo-postdoc-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/jobs/2024-nwo-postdoc.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-jobs-2024-nwo-postdoc-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-meetings-index-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/meetings/index.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-meetings-index-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-research-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/research.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-research-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-site-about-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/site/about.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-site-about-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-site-disclaimer-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/site/disclaimer.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-site-disclaimer-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-site-test-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/site/test.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-site-test-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-students-projects-2023-memory-and-familiar-music-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/students/projects/2023-memory-and-familiar-music.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-students-projects-2023-memory-and-familiar-music-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-students-projects-2024-wp-3-analyses-battery-results-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/students/projects/2024-wp3-analyses-battery-results.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-students-projects-2024-wp-3-analyses-battery-results-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-toontjehogerkids-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/toontjehogerkids.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-toontjehogerkids-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-tunetwins-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/tunetwins.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-tunetwins-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-vulpennenbeheer-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/vulpennenbeheer.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-vulpennenbeheer-mdx" */),
  "component---src-templates-page-jsx-content-file-path-src-content-wp-5-mdx": () => import("./../../../src/templates/page.jsx?__contentFilePath=/opt/build/repo/src/content/wp5.mdx" /* webpackChunkName: "component---src-templates-page-jsx-content-file-path-src-content-wp-5-mdx" */),
  "component---src-templates-people-jsx-content-file-path-src-content-people-mdx": () => import("./../../../src/templates/people.jsx?__contentFilePath=/opt/build/repo/src/content/people.mdx" /* webpackChunkName: "component---src-templates-people-jsx-content-file-path-src-content-people-mdx" */),
  "component---src-templates-press-item-jsx": () => import("./../../../src/templates/press-item.jsx" /* webpackChunkName: "component---src-templates-press-item-jsx" */),
  "component---src-templates-press-jsx-content-file-path-src-content-press-mdx": () => import("./../../../src/templates/press.jsx?__contentFilePath=/opt/build/repo/src/content/press.mdx" /* webpackChunkName: "component---src-templates-press-jsx-content-file-path-src-content-press-mdx" */),
  "component---src-templates-publications-jsx-content-file-path-src-content-publications-mdx": () => import("./../../../src/templates/publications.jsx?__contentFilePath=/opt/build/repo/src/content/publications.mdx" /* webpackChunkName: "component---src-templates-publications-jsx-content-file-path-src-content-publications-mdx" */),
  "component---src-templates-students-jsx-content-file-path-src-content-students-index-mdx": () => import("./../../../src/templates/students.jsx?__contentFilePath=/opt/build/repo/src/content/students/index.mdx" /* webpackChunkName: "component---src-templates-students-jsx-content-file-path-src-content-students-index-mdx" */),
  "component---src-templates-user-jsx": () => import("./../../../src/templates/user.jsx" /* webpackChunkName: "component---src-templates-user-jsx" */),
  "slice---src-components-slices-footer-jsx": () => import("./../../../src/components/slices/footer.jsx" /* webpackChunkName: "slice---src-components-slices-footer-jsx" */)
}

